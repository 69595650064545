<template>
  <b-modal id="modal-save-transaction" hide-header hide-footer centered title="Vertically Centered" ok-only
    ok-title="Accept">
    <div class="p-2 d-flex justify-content-between">
      <div />
      <h1 class="h4 text-dark font-weight-bolder mb-0">Simpan Transaksi</h1>
      <feather-icon class="text-dark font-weight-bolder" size="20" icon="XIcon"
        @click="$bvModal.hide('modal-save-transaction')" />
    </div>
    <hr class="mt-0" />
    <b-container>
      <b-row class="p-2">
        <b-col lg="8" class="pr-0">
          <b-form-group class="size-14" label="Nama Antrian" label-for="basicInput">
            <validation-provider #default="{ errors }" name="Nama Antrian" rules="required">
              <b-form-input v-model="queue_name" :state="errors.length > 0 ? false : null" placeholder="Masukan nama" />
              <small class="text-danger d-flex align-items-center" style="margin-top: 5px">
                <feather-icon v-if="errors.length > 0" icon="AlertCircleIcon" style="margin-right: 5px" />
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group class="size-14" label="Urutan ke-" label-for="urutan">
            <validation-provider #default="{ errors }" name="Urutan Ke-" rules="required">
              <b-form-input v-model="queue_number" :state="errors.length > 0 ? false : null" placeholder="Urutan Ke-" />
              <small class="text-danger d-flex align-items-center" style="margin-top: 5px">
                <feather-icon v-if="errors.length > 0" icon="AlertCircleIcon" style="margin-right: 5px" />
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <div class="button__group">
      <b-button id="button--cancel__transaction" class="button__cancel"
        @click="$bvModal.hide('modal-save-transaction')">
        Batal
      </b-button>
      <!-- <b-button
        id="button--save__transaction"
        class="button__save"
        @click="$bvModal.show('modal-transaction')"
      >
        Simpan
      </b-button> -->
      <b-button id="button--save__transaction" class="button__save" :disabled="isLoading" @click="saveCart">
        <b-spinner v-if="isLoading" variant="primary" label="Loading..." />
        Simpan
      </b-button>
    </div>
    <TransactionModal />
  </b-modal>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TransactionModal from "@/components/Cashier/Modal/TransactionForm.vue";
import {
  BFormInput,
  BRow,
  BCol,
  BModal,
  VBToggle,
  BButton,
  BFormGroup,
  BContainer,
  BSpinner,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BButton,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    TransactionModal,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      is_ritel: true,
      queue_name: "",
      queue_number: "",
      isLoading: false,
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    '$store.state.customer.customer'(customer) {
      if (customer) {
        this.queue_name = customer.name
      }
    }
  },
  created() {
    this.getQueueNumber();
  },
  before() { },
  methods: {
    saveCart() {
      this.isLoading = true;
      this.$store
        .dispatch("cart/saveCart", {
          uuid: `${this.$store.state.cart.cartUuid}?queue_name=${this.queue_name
            }&queue_number=${this.queue_number}${this.$store.state.checkout.formPayload.customer_uuid
              ? `&customer_uuid=${this.$store.state.checkout.formPayload.customer_uuid}`
              : ""
            }`,
          params: "",
        })
        .then((result) => {
          successNotification(this, "Success", "Transaksi berhasil disimpan");
          this.isLoading = false;
          this.$swal({
            title: "Konfirmasi",
            text: "Silahkan lengkapi Data Transaksi",
            icon: "warning",
            showCancelButton: true,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            confirmButtonText: "Ya",
            cancelButtonText: "Lengkapi Nanti",
            buttonsStyling: false,
          }).then((result) => {
            console.log(result);
            if (result.value) {
              this.$bvModal.show("modal-transaction");
              // this.$bvModal.hide("modal-save-transaction");
            } else if (result.isDismissed) {
              this.$bvModal.hide("modal-save-transaction");
              this.getActiveCart();
            }
          });
          // this.$router.push({ name: "cashier.index" });
          // window.location.reload();
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getActiveCart() {
      await this.$store
        .dispatch("cart/getCart", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          const item = result.data.data;
          this.$store.commit("cart/setDiscountCart", item.discount_value);
          this.$store.commit("cart/setDiscountType", item.discount_type);
          this.$store.commit("cart/setDiscountTotal", item.discount_total);
          this.$store.commit("cart/setSubtotalCart", item.subtotal);
          this.$store.commit("cart/setTotalPrice", item.total);
          this.$store.commit("cart/setTotalBuy", item.items.length);
          this.$store.commit("cart/setCartUuid", item.uuid);
          this.$store.commit("cart/setResultCartActive", item);
          this.$store.commit("cashier/setProduct", item.items.length);
          localStorage.setItem("cash_total_payment", item.total);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    getQueueNumber() {
      this.$store
        .dispatch("cart/getNextQueueNumber")
        .then((result) => {
          this.queue_number = result.data.data.next_queue_number;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/variables/_variables.scss";

#modal-save-transaction {
  .modal-dialog {
    .modal-content {
      background: #fcfcfc;
      border-radius: 16px;

      .modal-body {
        padding: 0;

        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }

          input {
            background: #e7e7ed;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }

        .button__group {
          display: flex;
          width: 100%;

          .button__cancel {
            background-color: #ffffff !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45b6ab !important;
            border-color: #45b6ab !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }
    }
  }
}
</style>
