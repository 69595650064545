<template>
  <b-modal
    id="modal-transaction"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header
        class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
      >
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div
              style="cursor: pointer"
              @click="$bvModal.hide('modal-transaction')"
            >
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
                >Kembali</span
              >
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Lengkapi data Transaksi
            </h1>
          </b-col>
          <b-col cols="3" class="text-right">
            <b-button class="px-3" @click="updateCart()">
              <b-spinner small v-if="isLoading" />
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center">
          <b-col cols="5">
            <div class="d-flex">
              <div
                id="tour-pickup"
                class="card--sender mr-1"
                :class="{ active: delivery_pickup === 'pickup' }"
                @click="delivery_pickup = 'pickup'"
              >
                <div class="circle">
                  <feather-icon class="shopping-cart" icon="ShoppingCartIcon" />
                </div>
                <h6>Ambil Sendiri</h6>
              </div>
              <div
                id="tour-delivery"
                class="card--sender"
                :class="{ active: delivery_pickup === 'delivery' }"
                @click="delivery_pickup = 'delivery'"
              >
                <div class="circle">
                  <feather-icon class="truck" icon="TruckIcon" />
                </div>
                <h6>Dikirim</h6>
              </div>
            </div>
            <hr style="border: 1px solid #e4e5ec" />

            <b-form @submit.prevent="submit">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <b-form-group class="custom__form--input mb-0">
                    <label for="date-creation"
                      >Tanggal Transaksi
                      <span class="text-danger">*</span></label
                    >
                    <b-form-input
                      id="date-creation"
                      v-model="formPayload.date"
                      class="custom__input pt-1"
                      type="date"
                      :max="todayDate"
                      placeholder="DD/MM/YYYY"
                      required
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-2"
                  v-if="delivery_pickup === 'delivery'"
                >
                  <b-form-group class="custom__form--input mb-0">
                    <label for="date-creation"
                      >Tanggal Pengiriman
                      <span class="text-danger">*</span></label
                    >
                    <b-form-input
                      id="date-creation"
                      v-model="formPayload.delivery_date"
                      class="custom__input pt-1"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      required
                    />
                  </b-form-group>
                </b-col>

                <!-- Sales -->
                <b-col cols="12" class="mb-2">
                  <b-form-group class="custom__form--input mb-0">
                    <label for="v-sales">Sales</label>
                    <v-select
                      v-model="formPayload.seller_uuid"
                      label="title"
                      :reduce="(option) => option.label"
                      :options="options"
                      placeholder="Pilih Sales"
                      class="text-dark fw-bold-700"
                      @search="onSearch"
                    />
                  </b-form-group>
                </b-col>
                <!-- Faktur, Non Faktur -->
                <b-col cols="12" class="mb-2">
                  <label
                    class="text-dark fw-bold-600"
                    style="font-size: 14px !important"
                    >Pilih Faktur atau Non-faktur
                    <span class="text-danger">*</span></label
                  ><br />
                  <small class="size12 text-dark text-darken-4 fw-bold-600"
                    >Pilih faktur jika transaksi ini memerlukan faktur
                    pajak</small
                  >
                  <b-form-group class="custom__form--input mb-0 mt-1">
                    <b-form-radio
                      v-model="formPayload.invoice_status"
                      name="some-radios"
                      value="1"
                      class="mb-1"
                    >
                      Faktur Pajak
                    </b-form-radio>
                    <b-form-radio
                      v-model="formPayload.invoice_status"
                      name="some-radios"
                      value="0"
                    >
                      Non-faktur Pajak
                    </b-form-radio>
                    <!-- <b-form-select
                  v-model="selectedFaktur"
                  :options="options"
                /> -->
                  </b-form-group>
                </b-col>
                <!-- nomor po -->
                <b-col
                  v-if="customerType !== 'Non Ritel'"
                  cols="12"
                  class="mb-2"
                >
                  <b-form-group
                    class="custom__form--input mb-0"
                    label="Nomor PO / SO"
                    label-for="v-nomor-po"
                  >
                    <b-form-input
                      id="v-nomor-po"
                      v-model="formPayload.po_so_number"
                      class="custom__input"
                      type="text"
                      placeholder="x-xxxx-xxxx"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- nama penerima -->
                <b-col
                  cols="12"
                  class="mb-2"
                  v-if="delivery_pickup === 'delivery'"
                >
                  <label
                    label-for="penerima"
                    class="text-dark fw-bold-600"
                    style="font-size: 14px !important"
                    >Nama Penerima <span class="text-danger">*</span></label
                  >
                  <b-form-group class="custom__form--input mb-0">
                    <b-form-input
                      id="penerima"
                      v-model="formPayload.receiver_name"
                      class="custom__input"
                      placeholder="Nama Anda"
                    />
                  </b-form-group>
                </b-col>

                <!-- Nomor Handphone Penerima -->
                <b-col cols="12" md="12" class="mb-2 custom__form--input">
                  <label for="v-phone"
                    >Telpon Penerima
                    <span
                      class="text-danger"
                      v-if="delivery_pickup === 'delivery'"
                      >*</span
                    ></label
                  >
                  <b-row>
                    <b-col cols="4" class="pr-0">
                      <v-select
                        v-model="formPayload.selectedCountry"
                        :label="currentLabel"
                        :options="countryListData"
                        :clearable="false"
                        @keyup.stop.native="searchCountry"
                      >
                        <template
                          #selected-option="{ country_flag, dial_code_preview }"
                        >
                          <div
                            class="d-flex align-items-center"
                            style="gap: 6px"
                          >
                            <img
                              :src="country_flag"
                              :alt="dial_code_preview"
                              style="width: 24px; height: 24px"
                            />
                            <p class="size12 mb-0">({{ dial_code_preview }})</p>
                          </div>
                        </template>
                        <template #option="{ country_flag, dial_code_preview }">
                          <div
                            class="d-flex align-items-center"
                            style="gap: 8px"
                          >
                            <img
                              :src="country_flag"
                              :alt="dial_code_preview"
                              style="width: 24px; height: 24px"
                            />
                            <p class="mb-0">{{ dial_code_preview }}</p>
                          </div>
                        </template>
                      </v-select>
                    </b-col>
                    <b-col cols="8">
                      <b-form-group>
                        <b-form-input
                          id="v-phone"
                          v-model="formattedPhone"
                          type="tel"
                          placeholder="Nomor Telpon Penerima"
                          class="custom__input"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- Alamat Penerima -->
                <b-col
                  cols="12"
                  class="mb-2 custom__form--input"
                  v-if="delivery_pickup === 'delivery'"
                >
                  <!-- <label>Alamat Penerima (Opsional)</label>
              <QuilEditor :payload="formPayload.receiver_address" @contentText="getContentText" /> -->
                  <label
                    label-for="address"
                    class="text-dark fw-bold-600"
                    style="font-size: 14px !important"
                    >Alamat Penerima<span class="text-danger">*</span></label
                  >
                  <b-form-group class="custom__form--input mb-0">
                    <b-form-textarea
                      id="address"
                      v-model="formPayload.receiver_address"
                      rows="3"
                      placeholder="Alamat"
                      class="custom__textarea"
                      maxlength="100"
                    />
                  </b-form-group>
                  <small class="size12 text-dark text-darken-4 font-italic">
                    *Maksimal 100 Karakter
                  </small>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
import _ from "lodash";
import vSelect from "vue-select";
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormRadio,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";
import moment from "moment";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";
import QuilEditor from "@/components/QuilEditor.vue";
// import customer from "@/store/modules/customer";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BButton,
    BSpinner,
    BFormTextarea,
    QuilEditor,
    vSelect,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  data() {
    const newDate = new Date();
    const endDate = moment(newDate).format("YYYY-MM-DD");
    const customerData = JSON.parse(localStorage.getItem("customer_data"));
    return {
      invoice_status: "",
      todayDate: endDate,
      selectedFaktur: "",
      delivery_pickup: "pickup",
      formPayload: {
        delivery_pickup: "pickup",
        payment_method: "",
        customer_uuid: "",
        // cash_total_payment: 0,
        invoice_status: 0,
        po_so_number: "",
        receiver_name: customerData?.name || "",
        receiver_phone_number: customerData?.phone_number || "",
        receiver_phone_number_country: customerData?.phone_number_country || "",
        receiver_address: customerData?.address || "",
        selectedCountry: {
          country_code:
            customerData?.phone_number_country?.country_code || "ID",
          country_flag:
            customerData?.phone_number_country?.country_flag ||
            "https://flagsapi.com/ID/shiny/64.png",
          country_name:
            customerData?.phone_number_country?.country_name || "Indonesia",
          dial_code_preview:
            customerData?.phone_number_country?.dial_code_preview || "+62",
          dial_code_value:
            customerData?.phone_number_country?.dial_code_value || "62",
        },
        date: endDate,
        delivery_date: endDate,
        display_vat: 0,
        invoice_notes: "",
      },
      // additional_costs: [],
      customerType: localStorage.getItem("customer_type"),
      customerUuid: localStorage.getItem("customer_uuid"),
      isLoading: false,
      options: [],
      countryListData: [],
      searchQueryData: "",
    };
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQueryData)
        ? "dial_code_value"
        : "country_name";
    },
    dialCode() {
      const selectedCountry = this.countryListData.find(
        (country) =>
          country.country_code ===
          this.formPayload.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "62";
    },
    formattedPhone: {
      get() {
        let phoneNumber = this.formPayload.receiver_phone_number || "";
        const regex = new RegExp(`^${this.dialCode}`);
        phoneNumber = phoneNumber.replace(regex, "");
        if (phoneNumber.startsWith("0")) {
          phoneNumber = phoneNumber.substring(1);
        }
        return phoneNumber;
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        // this.formPayload.onlyRecheiverPhone = value;
        this.formPayload.receiver_phone_number = this.dialCode + value;
      },
    },
  },

  watch: {
    delivery_pickup(value) {
      this.formPayload.delivery_pickup = value;
      if (value === "pickup") {
        delete this.formPayload.receiver_name;
        delete this.formPayload.receiver_address;
        this.additional_costs = [];
      }
    },
    "formPayload.selectedCountry"(newVal, oldVal) {
      const oldDialCode = oldVal ? oldVal.dial_code_value : "";
      const newDialCode = newVal.dial_code_value;
      if (this.formPayload.receiver_phone_number.startsWith(oldDialCode)) {
        this.formPayload.receiver_phone_number =
          newDialCode +
          this.formPayload.receiver_phone_number.replace(oldDialCode, "");
      }
    },
  },
  mounted() {
    this.getSales();
    this.getCountry();
  },
  methods: {
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQueryData = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          const uniqueCountries = response.data.data.data.filter((country, index, self) =>
              index === self.findIndex((c) => c.country_code === country.country_code)
          );
          this.countryListData = uniqueCountries
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchSales(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.options = [];
          this.getSales();
          loading(false);
        }, 500);
      }
    },
    searchSales: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("sales/getData", {
          uuid: "",
          params: {
            search: search,
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.options = [];
          response.data.data.data.forEach((item) => {
            vm.options.push({
              title: item.name,
              label: item.uuid,
            });
          });
          loading(false);
        });
    }, 300),
    getSalesDetail(id) {
      this.$store
        .dispatch("sales/getData", {
          uuid: `/${id}`,
          params: "",
        })
        .then((result) => {
          // this.uom = result.data.data.name
          this.options.push({
            label: result.data.data.uuid,
            title: result.data.data.name,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    getSales() {
      this.$store
        .dispatch("sales/getData", {
          uuid: "",
          params: {
            per_page: 15,
          },
        })
        .then((result) => {
          for (let index = 0; index < result.data.data.data.length; index++) {
            const element = result.data.data.data[index];
            this.options.push({
              title: element.name,
              label: element.uuid,
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    preparePayload() {
      this.formPayload.selectedCountry != null
        ? (this.formPayload.receiver_phone_number_country =
            this.formPayload.selectedCountry.country_code)
        : "";
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
    updateCart() {
      let form = this.preparePayload();
      this.isLoading = true;
      // delete this.formPayload.selectedCountry;
      this.$store
        .dispatch("cart/updateCart", {
          uuid: `${this.$store.state.cart.cartUuid}`,
          payload: form,
        })
        .then((result) => {
          successNotification(this, "Success", "Transaksi berhasil disimpan");
          this.isLoading = false;
          // this.$bvModal.hide("modal-transaction");
          this.$router.push({ name: "cart.index" });
          // window.location.reload();
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/tour.scss";

.v-tour {
  .v-step {
    background-color: #334155;

    .v-step__arrow {
      border-color: #334155;
    }
  }
}
.card--sender {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  width: 100%;
  padding: 3rem 2rem;
  margin-bottom: 2rem;
  cursor: pointer;

  background: #ffffff;

  &.active {
    background: rgba(82, 206, 194, 0.1);
    border: 1px solid #45b6ab;

    h6 {
      color: #45b6ab;
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background: #fef9f5;
    border-radius: 50%;
    box-shadow: inset 1.67273px 1.67273px 3.34545px rgba(230, 219, 211, 0.2);

    .shopping-cart {
      font-weight: bolder;
      color: #f1b277;
    }

    .truck {
      font-weight: bolder;
      color: #e04444;
    }
  }

  h6 {
    margin-top: 5px;
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }
}
</style>

<style lang="scss" scoped>
body {
  background: #ffffff;
}

.form-payment {
  height: 100vh;

  .custom__form--input mb-0 {
    label {
      margin-bottom: 1rem !important;
    }
  }

  .image-box {
    width: 46px;
    height: 46px;
  }

  @mixin title-card {
    margin-bottom: 0 !important;
    font-weight: 800;
    font-size: 14px;
    color: #2b324f;
  }

  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    &.active--payment {
      background: rgba(82, 206, 194, 0.1);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      border: 1px solid #45b6ab;

      h5 {
        color: #45b6ab !important;
        font-weight: 800;
        margin-bottom: 0px;
      }
    }

    &.card--full__payment {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      padding: 21.5px 16px;

      h5 {
        @include title-card;
        align-self: center;
      }

      span {
        font-weight: 800;
        font-size: 16px;
        color: #2b324f;
      }
    }

    &.card--down__payment {
      display: flex;
      justify-content: space-between;
      padding: 21.5px 16px;

      h5 {
        @include title-card;
      }

      small {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
</style>
