<template>
  <div>
    <b-card no-body class="mb-0 mt-2 mx-2" style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04); border-radius: 12px">
      <b-card-body id="tour-select-customer" v-b-modal.modal-select-customer
        class="d-flex flex-row align-items-center justify-content-between p-1" @click="tourCustomer">
        <div class="d-flex align-items-center">
          <b-avatar variant="primary" size="43" :text="customer.hasOwnProperty('name')
              ? Object.keys(customer).length > 0
                ? customer.name
                : 'CS'
              : Object.keys(customer).length > 0
                ? customer.receiver_name || customer.customer.name
                : 'CS'
            " />
          <div class="ml-1">
            <h1 class="h6 mb-0 text-dark font-weight-bolder">
              <span v-if="customer.hasOwnProperty('name')">
                {{
                  Object.keys(customer).length > 0 ? customer.name : "Pelanggan"
                }}
              </span>
              <span v-else>
                {{
                  Object.keys(customer).length > 0
                    ? customer.receiver_name || customer.customer.name
                    : "Pelanggan"
                }}
              </span>
            </h1>
            <small class="text-dark text-darken-5">
              <span v-if="customer.hasOwnProperty('phone_number')">
                {{
                  Object.keys(customer).length > 0 ? customer.phone_number : ""
                }}
              </span>
              <span v-else>
                {{
                  Object.keys(customer).length > 0
                    ? customer.receiver_phone_number ||
                    customer.customer.phone_number
                    : ""
                }}
              </span>
            </small>
          </div>
        </div>
        <feather-icon class="text-dark text-darken-4" size="24" icon="ChevronRightIcon" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { localize } from "vee-validate";
import { required } from "@validations";
import { BCard, VBToggle, BCardBody, BAvatar } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      required,
      is_ritel: true,
      customer: this.$store.state.customer.customer,
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  watch: {
    "$store.state.customer.customer": {
      handler(value) {
        this.customer = value;
        console.log(this.customer, "data customer");
      },
      deep: true,
    },
    "$store.state.cashier.userData": {
      handler(value) {
        this.userData = value;
      },
      deep: true,
    },
  },
  mounted() {
    // this.customer = this.$store.state.customer.customer;
    // console.log(this.customer, 'HALO');
    this.userData = JSON.parse(localStorage.getItem("userData"));
    localize("id");
  },
  methods: {
    tourCustomer() {
      this.$tours.cashierTour5.stop();
      this.$tours.cashierTour3.stop();
      if (!this.userData.finish_tutorial) {
        setTimeout(() => {
          this.$tours.cashierTour4.start();
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
</style>
